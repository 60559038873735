import { EnvironmentUrls, Endpoints } from 'Roblox';
import { urlService } from 'core-utilities';
import { upsellUtil } from 'core-roblox-utilities';

export function redirectToCustomizeAvatar() {
  const customizeAvatarUrl = Endpoints.getAbsoluteUrl('/my/avatar');
  if (customizeAvatarUrl !== undefined && customizeAvatarUrl !== '') {
    upsellUtil.expireUpsellCookie();
    window.location.href = customizeAvatarUrl;
  }
}

export function redirectToCatalogShop() {
  upsellUtil.expireUpsellCookie();
  window.location.href = Endpoints.getAbsoluteUrl('/catalog');
}

export function redirectToItemPath(itemPath: string) {
  upsellUtil.expireUpsellCookie();
  window.location.href = Endpoints.getAbsoluteUrl(itemPath);
}

export function redirectToRobuxStore(isTablet: boolean, isUniversalApp: boolean) {
  // (04/18/2024): Tablet UA seems to need this route specifically for prompting the native purchase prompts. This is a workaround
  // to enable Tablet UA Gamepass upsells.
  if (isTablet && isUniversalApp) {
    window.location.href = urlService.getUrlWithQueries('/Upgrades/Robux.aspx', { ctx: '' });
    return;
  }

  window.location.href = Endpoints.getAbsoluteUrl('/upgrades/robux?ctx=upsell');
}

export function redirectToVngShop() {
  window.open(EnvironmentUrls.vngGamesShopUrl, '_blank');
}
